<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-lone-template -->
<template>
  <section
    class="text-soft-black relative bg-white px-4 pb-11 pt-6 md:px-0 md:pb-16 md:pt-12"
  >
    <div class="relative z-10 mx-auto">
      <h2
        class="mb-6 pb-3 text-center text-2xl text-supporting-black-87 md:mb-10 md:px-0 md:text-3xl"
      >
        {{ block.heading ?? $t('editorial.insurance_companies_heading') }}
      </h2>

      <div class="md:hidden">
        <Swiper class="-m-4 md:hidden" :show-arrows="false">
          <template #items>
            <NuxtLink
              v-for="partner in globals.insurancePartners"
              :key="partner.id + '-mobile'"
              :href="partner.redirectUrl"
              class="flex w-1/2 shrink-0 items-center overflow-hidden rounded-xl px-8 py-2 md:px-9 [&:nth-child(2n+1)]:snap-start"
            >
              <NuxtImg
                v-if="partner?.insuranceCompanyLogoLarge?.[0]?.url"
                loading="lazy"
                width="200"
                class="max-w-1/2 max-h-20 object-contain"
                :src="partner?.insuranceCompanyLogoLarge?.[0]?.url"
                :alt="partner.title"
              />
            </NuxtLink>
          </template>
        </Swiper>
      </div>

      <div
        ref="marquee"
        class="marquee-container hidden flex-nowrap items-center gap-20 overflow-auto px-6 md:flex"
      >
        <NuxtLink
          v-for="partner in globals.insurancePartners"
          :key="partner.id"
          :href="partner.redirectUrl"
        >
          <NuxtImg
            v-if="partner?.insuranceCompanyLogoLarge?.[0]?.url"
            loading="lazy"
            width="200"
            class="m-auto max-h-20 max-w-36 object-contain"
            :src="partner?.insuranceCompanyLogoLarge?.[0]?.url"
            :alt="partner.title"
          />
        </NuxtLink>
        <NuxtLink
          v-for="partner in globals.insurancePartners"
          :key="partner.id + '-2'"
          :href="partner.redirectUrl"
        >
          <NuxtImg
            v-if="partner?.insuranceCompanyLogoLarge?.[0]?.url"
            loading="lazy"
            width="200"
            class="m-auto max-h-20 max-w-36 object-contain"
            :src="partner?.insuranceCompanyLogoLarge?.[0]?.url"
            :alt="partner.title"
          />
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

let scrollStep = 0;
const scrollSpeed = 0.5;
const animationFrameId = ref<number | null>(null);
const isPaused = ref(false);
const isVisible = ref(false);

const marquee = ref<HTMLElement | null>(null);

defineProps({
  block: {
    type: Object,
    default: () => ({}),
  },
  globals: {
    type: Object,
    default: () => ({}),
  },
});

const scrollMarquee = () => {
  if (!marquee.value) return;
  if (!isPaused.value) {
    if (marquee.value.scrollLeft >= marquee.value.scrollWidth / 2 + 15) {
      marquee.value.scrollLeft = 0;
    } else {
      scrollStep += scrollSpeed;
      if (scrollStep >= 1) {
        marquee.value.scrollLeft += 1;
        scrollStep = 0;
      }
    }
  }
  animationFrameId.value = requestAnimationFrame(scrollMarquee);
};

const pauseMarquee = () => {
  isPaused.value = true;
};

const resumeMarquee = () => {
  isPaused.value = false;
};

onMounted(() => {
  if (!marquee.value) return;

  marquee.value.addEventListener('mousedown', pauseMarquee);
  marquee.value.addEventListener('touchstart', pauseMarquee);
  marquee.value.addEventListener('mouseenter', pauseMarquee);
  marquee.value.addEventListener('mouseup', resumeMarquee);
  marquee.value.addEventListener('touchend', resumeMarquee);
  marquee.value.addEventListener('mouseleave', resumeMarquee);
  marquee.value.addEventListener('focus', pauseMarquee, true);
  marquee.value.addEventListener('blur', resumeMarquee, true);

  scrollMarquee();
});

onUnmounted(() => {
  if (!marquee.value) return;

  marquee.value.removeEventListener('mousedown', pauseMarquee);
  marquee.value.removeEventListener('touchstart', pauseMarquee);
  marquee.value.removeEventListener('mouseenter', pauseMarquee);
  marquee.value.removeEventListener('mouseup', resumeMarquee);
  marquee.value.removeEventListener('touchend', resumeMarquee);
  marquee.value.removeEventListener('mouseleave', resumeMarquee);
  marquee.value.removeEventListener('focus', pauseMarquee, true);
  marquee.value.removeEventListener('blur', resumeMarquee, true);

  if (animationFrameId.value) {
    cancelAnimationFrame(animationFrameId.value);
  }
});

let observerSwiperInstance: IntersectionObserver | null = null;

onMounted(() => {
  if (!marquee.value) return;
  const observerOptions = {
    threshold: 0.1,
  };
  observerSwiperInstance = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      isVisible.value = entry.isIntersecting;
    });
  }, observerOptions);
  if (observerSwiperInstance && marquee.value) {
    observerSwiperInstance.observe(marquee.value);
  }
});

onUnmounted(() => {
  if (observerSwiperInstance) {
    observerSwiperInstance.disconnect();
  }
});

// Watch for visibility changes to pause/resume marquee
watch(isVisible, (newIsVisible) => {
  if (newIsVisible) {
    resumeMarquee();
  } else {
    pauseMarquee();
  }
});
</script>

<style scoped>
.marquee-container::-webkit-scrollbar {
  display: none;
}
.marquee-container {
  scrollbar-width: none;
}
</style>
